import React, { useState } from "react";
import Lottie from "lottie-react";
import BuyTokenGIF from "../../Assist/Animation - 1731043608468.json";
import Navbar from "../Navbar_GPTPage/Navbar_GPTPage";
import Sidebar from "../Sidebar/Sidebar";
import ModalForm from "../GenerativeAIExpertPopup/GenerativeAIExpertPopup";
import "./BuyToken.css";

import Footer from "../Footer/Footer";

import { Helmet } from "react-helmet";


const Buy_token = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (

    <>

      <div className={`Buy-token-section ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Helmet>
          <title>ardraGPT | Buy Tokens </title>
          <meta name="description" content="Purchase credits for Ardra AI's services. Talk to our Generative AI experts to find the right package for you, or book a demo now." />
          <meta name="keywords" content="Buy Tokens, Purchase Credits, Ardra AI, Generative AI, AI Experts, Book Demo, AI Credit Packages" />
          <meta name="author" content="Ardra AI" />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Buy Tokens - Ardra AI" />
          <meta property="og:description" content="Purchase credits for Ardra AI's services. Talk to our Generative AI experts to find the right package for you, or book a demo now." />
          <meta property="og:image" content="/path-to-image.jpg" />
          <meta property="og:url" content="https://ardra.ai/buy-tokens" />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://ardra.ai/buy-tokens" />
        </Helmet>

        <Navbar isSidebarOpen={isSidebarOpen} />
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="Buy-token-link-section">
          <div className="Buy-token-link-GIF">
            <Lottie
              className="lottie-Buy-token-GIF"
              animationData={BuyTokenGIF}
              loop={true}
            />
          </div>
          <div className="Buy-token-link-content">
            <h3>Purchase Credits 💰</h3>
            <p>
              Please reach out to us for purchasing additional credits. Our team is here to help you get started and make the most of your experience.
              If you need assistance with selecting the right credit package or have questions, feel free to talk to one of our Generative AI experts.
            </p>


            <button className="Buy-token-button" onClick={openModal}>
              Talk to a Generative AI Expert
            </button>
            <ModalForm isOpen={isModalOpen} onClose={closeModal} />
            <a
              href="https://calendly.com/pranjal-2diz/ardra-ai-socials"
              target="_blank"
              rel="noopener noreferrer"
              className="Buy-token-demo-link"
            >
              Book a Demo
            </a>
          </div>
        </div>

      </div>
      <Footer isSidebarOpen={isSidebarOpen} />
    </>
  );
};

export default Buy_token;
